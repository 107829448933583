import React, { useState, useEffect } from "react";
import BreadcrumbNav from "../../../../components/BreadcrumbNav";
import { Routes } from "../../../../routes";
import { Row, Col, Card, Button, Badge } from "@themesberg/react-bootstrap";
import PermissionsCheck from "../../../../auth/PermissionCheck";
import { scopes } from "../../../../auth/PermissionsMap";
import CertificateCheckerService from "../../../services/CertificateCheckerService";
import UploadCertificatePanel from "./components/UploadCertificatePanel";
import CertificateChecklist from "./components/CertificateChecklist";
import { formatBytes, formatFileName } from "../../../../utils/formatFiles";
import { v4 as uuid } from "uuid";
import FormScripts from "../../../../components/FormScripts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-regular-svg-icons";

const CertificateCheckerPage = () => {
  const [isSubmitting, setSubmitting] = useState(false);
  const [checkResponses, setCheckResponses] = useState([]);
  const [checklist, setChecklist] = useState([]);
  const [errors, setErrors] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      // Standard message for beforeunload events
      event.preventDefault();
      event.returnValue = ""; // Required for Chrome
    };

    // Add event listener for beforeunload
    window.addEventListener("beforeunload", handleBeforeUnload);

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);
  const breadcrumbData = {
    item1: {
      name: "Tools",
      link: Routes.ToolsDashboardPage.path,
    },
    item2: {
      name: "Certificate Checker",
      link: null,
    },
  };

  const handleFilesAdded = (files) => {
    var newFiles = [];
    files.map((file) => {
      var fileSize = formatBytes(file.size, 0);
      var fileName = formatFileName(file.path);
      newFiles.push({
        id: uuid(),
        fullFile: file,
        file: file.path,
        size: fileSize,
        version: 0.0,
        formName: "",
        filename: fileName,
        selected: true,
        isProcessing: false,
        isAnalysing: false,
        isAnalysisComplete: false,
        isCompleted: false,
        isError: false,
        passed: false,
        errors: [],
      });
    });
    setChecklist((prev) => [...prev, ...newFiles]);
  };

  return (
    <div>
      <BreadcrumbNav data={breadcrumbData} />
      <Row className="row d-flex align-items-center">
        <Col xs={12} sm={6} lg={6} className="text-start">
          <h2>Certificate Verifier</h2>
        </Col>
        <Col xs={12} sm={6} lg={6} className="text-end">
          <Button
            variant="primary"
            className=""
            size="sm"
            onClick={() => {
              window.location.reload();
            }}
          >
            <FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" />
            Start again
          </Button>
        </Col>
      </Row>
      <Row className="g-4">{renderCards()}</Row>
    </div>
  );

  async function onCheckSubmit(values, { resetForm, setFieldValue }) {
    setSubmitting(true);
    try {
      // Clone the items array to avoid direct mutation
      const updatedItems = values.items.map((item) => ({ ...item }));

      var currentItem = null;
      for (const item of updatedItems) {
        currentItem = item;
        if (item.selected) {
          item.isProcessing = true;
          item.isCompleted = false;
          setFieldValue("items", updatedItems);
          const formData = new FormData();
          formData.append("file", item.fullFile);
          const response = await CertificateCheckerService.checkCertificate(
            formData
          );

          if (response.data?.data != null) {
            item.passed = response.data.data.passed;
            item.isCompleted = true;
            item.isProcessing = false;
            item.version = response.data.data.version;
            item.formName = response.data.data.name;
          }

          if (response.data.errors?.length > 0) {
            alert("error");
            item.passed = false;
            item.errors = response.data.errors;
            item.isProcessing = true;
            item.isCompleted = true;
          }
          console.log("response", response.data);
        }
      }

      console.log("updatedItems", updatedItems);
      setFieldValue("items", updatedItems);
      setChecklist(updatedItems); // Update state with the cloned array
    } catch (error) {
      currentItem.passed = false;
      currentItem.isProcessing = false;
      currentItem.isCompleted = true;
      currentItem.isError = true;
      currentItem.errors = ["An error occurred"];

      console.error("An error occurred", error);
    } finally {
      setSubmitting(false);
    }
  }
  async function onAnalysis(values, setFieldValue) {
    setSubmitting(true);
    // Clone the items array to avoid direct mutation
    const updatedItems = values.items
      .filter((x) => x.isAnalysisComplete != true)
      .map((item) => ({ ...item }));

    try {
      // Sequentially start async tasks, waiting for each one to finish
      for (const item of updatedItems) {
        if (item.selected) {
          item.isAnalysing = true;
          item.isAnalysisComplete = false;
          setFieldValue("items", [...updatedItems]);

          const formData = new FormData();
          formData.append("file", item.fullFile);

          try {
            const response = await CertificateCheckerService.analyseCertificate(
              formData
            );

            if (response.data.errors?.length > 0) {
              item.passed = false;
              item.errors = response.data.errors;
            } else {
              item.data = response.data.data;
              item.passed = true;
            }

            item.isAnalysing = false;
            item.isAnalysisComplete = true;
            item.analysis = response.data;

            console.log("response", response.data);
          } catch (error) {
            item.passed = false;
            item.isAnalysing = false;
            item.isAnalysisComplete = true;
            item.isError = true;
            item.errors = ["An error occurred"];
            console.error("An error occurred", error);
          }

          // Update the field value after the individual analysis completes
          setFieldValue("items", [...updatedItems]);
        }
      }
    } catch (error) {
      console.error("An error occurred", error);
    } finally {
      setSubmitting(false);
    }
  }

  function renderCards() {
    return (
      <PermissionsCheck
        errorProps={{ disabled: true }}
        scopes={[scopes.tools.certificatechecker]}
      >
        <Col xs={12} lg={8} className="my-4">
          <FormScripts />
          <CertificateChecklist
            checkResponses={checkResponses}
            isSubmitting={isSubmitting}
            onCheckSubmit={onCheckSubmit}
            onAnalysis={onAnalysis}
            checklist={checklist}
            setChecklist={setChecklist}
          />
        </Col>
        <Col xs={12} lg={4} className="my-4">
          <UploadCertificatePanel
            isSubmitting={isSubmitting}
            uploadedFiles={uploadedFiles}
            setUploadedFiles={setUploadedFiles}
            onAdded={handleFilesAdded}
          />
        </Col>
      </PermissionsCheck>
    );
  }
};
export default CertificateCheckerPage;
