import * as baseService from "./BaseService";

const analyseCertificate = (data) => {
  return baseService.postApiData(
    "/CertificateChecker/AnalyseCertificate",
    data
  );
};
const checkCertificate = (data) => {
  return baseService.postApiData(
    "/CertificateChecker/CheckCertificateDetails",
    data
  );
};
const CertificateCheckerService = {
  analyseCertificate,
  checkCertificate,
};

export default CertificateCheckerService;
