import React, { useState, useEffect } from "react";
import { msalConfig, loginRequest } from "../auth/authProvider";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import { Routes } from "../routes";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import useWindowSize from "../hooks/windowSizeHook";
import { InteractionType } from "@azure/msal-browser";
import ScrollToTop from "../components/ScrollToTop";
// pages
import Dashboard from "./components/Dashboard/Dashboard";
import NotFoundPage from "./error/NotFound";
import ServerError from "./error/ServerError";
// components
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Preloader from "../components/Preloader";

// pages

import ToolsDashboardPage from "./components/Tools/ToolsDashboardPage";
import CertificateCheckerPage from "./components/Tools/CertificateChecker/CertificateCheckerPage";
import CertificateEditor from "./components/Certificates/CertificateEditor/CertificateEditor";
import CertificateUpload from "./components/Certificates/CertificateUpload";
import CertificateListingPage from "./components/Certificates/CertificateListingPage";
import CertificateViewPage from "./components/Certificates/CertificateViewPage";
import ConsignmentAddPage from "./components/Consignments/ConsignmentAddPage";
import ConsignmentClonePage from "./components/Consignments/ConsignmentClonePage";
import ConsignmentContentsPage from "./components/Consignments/ConsignmentContents/ConsignmentContentsPage";
import ConsignmentListingPage from "./components/Consignments/ConsignmentListingPage";
import AttestationDashboard from "./components/Attestations/AttestationDashboard";
import DailyAttestationAddPage from "./components/Attestations/DailyAttestation/DailyAttestationAddPage";
import DailyAttestationClonePage from "./components/Attestations/DailyAttestation/DailyAttestationClonePage";
import DailyAttestationViewPage from "./components/Attestations/DailyAttestation/DailyAttestationViewPage";
import ProductListing from "./components/Products/ProductsListingPage";
import SupportAttestationListingPage from "./components/Attestations/SupportAttestationListingPage";
import ProductPage from "./components/Products/ProductPage";
import ProductAdd from "./components/Products/ProductAddPage";
import ProductDetailsAddPage from "./components/Products/ProductDetail/ProductDetailsAddPage";
import Composite8350Add from "./components/Products/Composite/Composite8350AddPage";
import EstablishmentListingPage from "./components/Establishments/EstablishmentListingPage";
import Dairy8468AddPage from "./components/Products/Dairy/Dairy8468AddPage";
import Egg8360AddPage from "./components/Products/Egg/Egg8360AddPage";
import Fishery8361AddPage from "./components/Products/Fishery/Fishery8361AddPage";
import MeatPreparation8383AddPage from "./components/Products/MeatPreparation/MeatPreparation8383AddPage";
import HighlyRefined8399AddPage from "./components/Products/HighlyRefined/HighlyRefined8399AddPage";
import Meat8384AddPage from "./components/Products/Meat/Meat8384AddPage";
import Porcine8370AddPage from "./components/Products/Porcine/Porcine8370AddPage";
import Poultry8371AddPage from "./components/Products/Poultry/Poultry8371AddPage";
import Ovine8369AddPage from "./components/Products/Ovine/Ovine8369AddPage";
import Bovine8368AddPage from "./components/Products/Bovine/Bovine8368AddPage";
import WildGame8367AddPage from "./components/Products/WildGame/WildGame8367AddPage";
import DogChews8325AddPage from "./components/Products/DogChews/DogChews8325AddPage";
import Casing8393AddPage from "./components/Products/Casing/Casing8393AddPage";
import Gelatine8390AddPage from "./components/Products/Gelatine/Gelatine8390AddPage";
import Collagen8396AddPage from "./components/Products/Collagen/Collagen8396AddPage";
import Honey8391AddPage from "./components/Products/Honey/Honey8391AddPage";
import VitaminD8392AddPage from "./components/Products/VitaminD/VitaminD8392AddPage";
import { FileUpload } from "./components/FileUpload";
import RequestAttestationPage from "./components/Attestations/AttestationRequest/GEFS/RequestAttestationPage";
import RequestAttestationNonGEFSPage from "./components/Attestations/AttestationRequest/NonGEFS/RequestAttestationNonGEFSPage";
import ResponseAttestationPage from "./components/Attestations/AttestationResponse/GEFS/ResponseAttestationPage";
import ResponseAttestationNonGEFSPage from "./components/Attestations/AttestationResponse/NonGEFS/ResponseAttestationNonGEFSPage";
import PlatformListingPage from "./components/Platforms/PlatformListingPage";
import PlatformViewPage from "./components/Platforms/PlatformViewPage";
import PlatformAdd from "./components/Platforms/PlatformAddPage";
import SiteAdd from "./components/Sites/SiteAddPage";
import SiteViewPage from "./components/Sites/SiteViewPage";
import SiteListingPage from "./components/Sites/SiteListingPage";
import ManufacturerListingPage from "./components/Manufacturers/ManufacturerListingPage";
import ManufacturerViewPage from "./components/Manufacturers/ManufacturerViewPage";
import ManufacturerAddPage from "./components/Manufacturers/ManufacturerAddPage";
import UserGuides from "./components/UserGuides";
import UserAdminPage from "./components/admin/UserAdminPage";
import AIAdmin from "./components/admin/AIAdmin";
import EstablishmentScraperPage from "./components/admin/EstablishmentScraperPage";
import VetListing from "./components/Vets/VetListingPage";
import VetAdd from "./components/Vets/VetAddPage";
import NotAuthorised from "./error/NotAuthorised";
import PermissionsCheck from "../auth/PermissionCheck";
import { scopes } from "../auth/PermissionsMap";
import { Provider } from "react-redux";
import store from "../store";
import { Container } from "@themesberg/react-bootstrap";
import Storage8461AddPage from "./components/Products/Storage/Storage8461AddPage";
import PlatformCertifierListingPage from "./components/PlatformCertifiers/PlatformCertifierListingPage";
import StorageSupplierDeclarationListingPage from "./components/StorageDeclaration/StorageSupplierDeclarationListingPage";
import ExporterDeclarationPage from "./components/StorageDeclaration/ExporterDeclaration/ExporterDeclarationPage";
import StorageExporterDeclarationListingPage from "./components/StorageDeclaration/StorageExporterDeclarationListingPage";
import StorageDeclarationDashboard from "./components/StorageDeclaration/StorageDeclarationDashboard";
import ReportsDashboard from "./components/Reports/ReportsDashboard";
import NoticeboardAdmin from "./components/admin/NoticeboardAdmin";
import { useLocation } from "react-router-dom";

const RouteWithLoader = ({ component: Component, ...rest }) => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setLoaded(true), 1000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Route
      {...rest}
      render={(props) => (
        <>
          {" "}
          <Preloader show={loaded ? false : true} /> <Component {...props} />{" "}
        </>
      )}
    />
  );
};

const RouteWithSidebar = ({ component: Component, path, scopes, ...rest }) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [width] = useWindowSize();
  useEffect(() => {
    const timer = setTimeout(() => {
      setLoaded(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const localStorageIsSettingsVisible = () => {
    return localStorage.getItem("settingsVisible") === "false" ? false : true;
  };

  const [showSettings, setShowSettings] = useState(
    localStorageIsSettingsVisible
  );

  const toggleSettings = () => {
    setShowSettings(!showSettings);
    localStorage.setItem("settingsVisible", !showSettings);
  };
  const onCollapse = () => {
    setSidebarCollapsed(!sidebarCollapsed);
  };
  const location = useLocation();

  useEffect(() => {
    // Check if the screen width is less than a threshold, e.g., 768px
    if (width < 768) {
      setSidebarCollapsed(true);
    } else {
      setSidebarCollapsed(false);
    }
  }, [location, width]); // React to changes in location and window width

  return (
    <>
      <ScrollToTop />
      <Preloader show={loaded ? false : true} />
      <Sidebar onCollapse={onCollapse} sidebarCollapsed={sidebarCollapsed} />

      <main className={`content ${sidebarCollapsed ? "collapsed" : ""}`}>
        <PermissionsCheck scopes={scopes}>
          <Route
            {...rest}
            path={path}
            render={(props) => (
              <>
                <Container fluid>
                  <Navbar />
                  <Component {...props} />
                </Container>
              </>
            )}
          />
          <Footer toggleSettings={toggleSettings} showSettings={showSettings} />
        </PermissionsCheck>
      </main>
    </>
  );
};

export default () => (
  <MsalAuthenticationTemplate
    interactionType={InteractionType.Redirect}
    authenticationRequest={loginRequest}
    errorComponent={NotAuthorised}
  >
    <BrowserRouter>
      <Provider store={store}>
        <Switch>
          <RouteWithLoader
            exact
            path={Routes.NotFound.path}
            component={NotFoundPage}
          />
          <RouteWithLoader
            exact
            path={Routes.ServerError.path}
            component={ServerError}
          />

          {/* pages */}
          <RouteWithSidebar
            exact
            path={Routes.ToolsDashboardPage.path}
            component={ToolsDashboardPage}
            scopes={[scopes.tools.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.CertificateCheckerPage.path}
            component={CertificateCheckerPage}
            scopes={[scopes.tools.certificatechecker]}
          />
          <RouteWithSidebar
            exact
            path={Routes.CertificateEditor.path}
            component={CertificateEditor}
            scopes={[scopes.certificates.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.CertificateUpload.path}
            component={CertificateUpload}
            scopes={[scopes.certificates.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.CertificateListingPage.path}
            component={CertificateListingPage}
            scopes={[scopes.certificates.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.CertificateViewPage.path}
            component={CertificateViewPage}
            scopes={[scopes.certificates.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ConsignmentAddPage.path}
            component={ConsignmentAddPage}
            scopes={[scopes.consignments.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ConsignmentEditPage.path}
            component={ConsignmentAddPage}
            scopes={[scopes.consignments.update]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ConsignmentClonePage.path}
            component={ConsignmentClonePage}
            scopes={[scopes.consignments.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ConsignmentContentsPage.path}
            component={ConsignmentContentsPage}
            scopes={[scopes.consignments.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ConsignmentListingPage.path}
            component={ConsignmentListingPage}
            scopes={[scopes.consignments.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.EstablishmentListingPage.path}
            component={EstablishmentListingPage}
            scopes={[scopes.establishments.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Dashboard.path}
            component={Dashboard}
          />
          <RouteWithSidebar
            exact
            path={Routes.AttestationDashboard.path}
            component={AttestationDashboard}
            scopes={[
              scopes.dailyattestations.create,
              scopes.dailyattestations.read,
            ]}
          />
          <RouteWithSidebar
            exact
            path={Routes.DailyAttestationViewPage.path}
            component={DailyAttestationViewPage}
            scopes={[scopes.dailyattestations.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.DailyAttestationAddPage.path}
            component={DailyAttestationAddPage}
            scopes={[scopes.dailyattestations.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.DailyAttestationClonePage.path}
            component={DailyAttestationClonePage}
            scopes={[scopes.dailyattestations.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.AddProduct.path}
            component={ProductAdd}
            scopes={[scopes.products.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Products.path}
            component={ProductListing}
            scopes={[scopes.products.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Product.path}
            component={ProductPage}
            scopes={[scopes.products.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.EditProduct.path}
            component={ProductAdd}
            scopes={[scopes.products.update]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ProductDetailAddPage.path}
            component={ProductDetailsAddPage}
            scopes={[scopes.productdetail.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.FileUpload.path}
            component={FileUpload}
            scopes={[scopes.products.createbulk]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Egg8360AddPage.path}
            component={Egg8360AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Egg8360AddPageNonGEFS.path}
            component={Egg8360AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Fishery8361AddPage.path}
            component={Fishery8361AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Fishery8361AddPageNonGEFS.path}
            component={Fishery8361AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Meat8384AddPage.path}
            component={Meat8384AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Meat8384AddPageNonGEFS.path}
            component={Meat8384AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Dairy8468AddPage.path}
            component={Dairy8468AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Dairy8468AddPageNonGEFS.path}
            component={Dairy8468AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.MeatPreparation8383AddPage.path}
            component={MeatPreparation8383AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.MeatPreparation8383AddPageNonGEFS.path}
            component={MeatPreparation8383AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.HighlyRefined8399AddPage.path}
            component={HighlyRefined8399AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.HighlyRefined8399AddPageNonGEFS.path}
            component={HighlyRefined8399AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Gelatine8390AddPage.path}
            component={Gelatine8390AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Gelatine8390AddPageNonGEFS.path}
            component={Gelatine8390AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.WildGame8367AddPage.path}
            component={WildGame8367AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.WildGame8367AddPageNonGEFS.path}
            component={WildGame8367AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Porcine8370AddPage.path}
            component={Porcine8370AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Porcine8370AddPageNonGEFS.path}
            component={Porcine8370AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Poultry8371AddPage.path}
            component={Poultry8371AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Poultry8371AddPageNonGEFS.path}
            component={Poultry8371AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Ovine8369AddPage.path}
            component={Ovine8369AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Ovine8369AddPageNonGEFS.path}
            component={Ovine8369AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Bovine8368AddPage.path}
            component={Bovine8368AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Bovine8368AddPageNonGEFS.path}
            component={Bovine8368AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.DogChews8325AddPage.path}
            component={DogChews8325AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.DogChews8325AddPageNonGEFS.path}
            component={DogChews8325AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Casing8393AddPage.path}
            component={Casing8393AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Casing8393AddPageNonGEFS.path}
            component={Casing8393AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Collagen8396AddPage.path}
            component={Collagen8396AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Collagen8396AddPageNonGEFS.path}
            component={Collagen8396AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Honey8391AddPage.path}
            component={Honey8391AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Honey8391AddPageNonGEFS.path}
            component={Honey8391AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.VitaminD8392AddPage.path}
            component={VitaminD8392AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.VitaminD8392AddPageNonGEFS.path}
            component={VitaminD8392AddPage}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Composite8350.path}
            component={Composite8350Add}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Storage8461AddPage.path}
            component={Storage8461AddPage}
            scopes={[scopes.productdetail.read, scopes.productdetail.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Composite8350NonGEFS.path}
            component={Composite8350Add}
            scopes={[scopes.productnongefs.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Platforms.path}
            component={PlatformListingPage}
            scopes={[scopes.platforms.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.PlatformViewPage.path}
            component={PlatformViewPage}
            scopes={[scopes.platforms.read]}
          />
          <RouteWithSidebar
            path={Routes.PlatformAdd.path}
            component={PlatformAdd}
            scopes={[scopes.platforms.create]}
          />
          <RouteWithSidebar
            path={Routes.PlatformEdit.path}
            component={PlatformAdd}
            scopes={[scopes.sites.update]}
          />
          <RouteWithSidebar
            exact
            path={Routes.PlatformCertifiersListingPage.path}
            component={PlatformCertifierListingPage}
            scopes={[scopes.platformcertifiers.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.Sites.path}
            component={SiteListingPage}
            scopes={[scopes.sites.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.AddSite.path}
            component={SiteAdd}
            scopes={[scopes.sites.create]}
          />
          <RouteWithSidebar
            path={Routes.SiteEdit.path}
            component={SiteAdd}
            scopes={[scopes.sites.update]}
          />
          <RouteWithSidebar
            exact
            path={Routes.SiteViewPage.path}
            component={SiteViewPage}
            scopes={[scopes.sites.read]}
          />
          <RouteWithSidebar
            path={Routes.ManufacturersListingPage.path}
            component={ManufacturerListingPage}
          />
          <RouteWithSidebar
            path={Routes.ManufacturerAddPage.path}
            component={ManufacturerAddPage}
            scopes={[scopes.manufacturers.create]}
          />
          <RouteWithSidebar
            path={Routes.ManufacturerEdit.path}
            component={ManufacturerAddPage}
            scopes={[scopes.manufacturers.update]}
          />
          <RouteWithSidebar
            path={Routes.ManufacturerViewPage.path}
            component={ManufacturerViewPage}
            scopes={[scopes.manufacturers.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.RequestAttestationPage.path}
            component={RequestAttestationPage}
            scopes={[scopes.attestationrequests.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.RequestAttestationNonGEFSPage.path}
            component={RequestAttestationNonGEFSPage}
            scopes={[scopes.attestationrequests.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ResponseAttestationPage.path}
            component={ResponseAttestationPage}
            scopes={[scopes.attestationresponses.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ResponseAttestationNonGEFSPage.path}
            component={ResponseAttestationNonGEFSPage}
            scopes={[scopes.attestationresponses.create]}
          />
          {/* <RouteWithSidebar exact path={Routes.RequestStorageDeclarationPage.path} component={RequestStorageDeclarationPage} scopes={[scopes.storagerequests.create]}   /> */}
          {/* <RouteWithSidebar exact path={Routes.ResponseStorageDeclarationPage.path} component={ResponseStorageDeclarationPage} scopes={[scopes.attestationresponses.create]}   /> */}
          <RouteWithSidebar
            exact
            path={Routes.SupportAttestations.path}
            component={SupportAttestationListingPage}
          />
          <RouteWithSidebar
            exact
            path={Routes.StorageDeclarationDashboard.path}
            component={StorageDeclarationDashboard}
            scopes={[scopes.storagedeclarations.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.StorageExporterDeclarationListingPage.path}
            component={StorageExporterDeclarationListingPage}
            scopes={[scopes.storageresponses.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.StorageSupplierDeclarationListingPage.path}
            component={StorageSupplierDeclarationListingPage}
            scopes={[scopes.storageresponses.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ExporterDeclarationPage.path}
            component={ExporterDeclarationPage}
            scopes={[scopes.storageresponses.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.UserAdminPage.path}
            component={UserAdminPage}
            scopes={[scopes.admin.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.AIAdmin.path}
            component={AIAdmin}
            scopes={[scopes.admin.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.EstablishmentScraperPage.path}
            component={EstablishmentScraperPage}
            scopes={[scopes.admin.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.UserGuides.path}
            component={UserGuides}
          />
          <RouteWithSidebar
            exact
            path={Routes.Vets.path}
            component={VetListing}
            scopes={[scopes.vets.read]}
          />
          <RouteWithSidebar
            exact
            path={Routes.AddVet.path}
            component={VetAdd}
            scopes={[scopes.vets.create]}
          />
          <RouteWithSidebar
            exact
            path={Routes.ReportsDashboard.path}
            component={ReportsDashboard}
            scopes={[scopes.reports.dashboard]}
          />
          <RouteWithSidebar
            exact
            path={Routes.NoticeboardAdmin.path}
            component={NoticeboardAdmin}
            scopes={[scopes.noticeboard.create]}
          />

          <Redirect to={Routes.NotFound.path} />
          {/* Your app components */}
        </Switch>
      </Provider>
    </BrowserRouter>
  </MsalAuthenticationTemplate>
);
